<template>
  <div>
    <div class="choose">
      <div class="container">
        <div class="title-mobile d_none" v-if="fourthblock[0].title" v-html="fourthblock[0].title"></div>
        <div class="choose-right ">
          <h3 class="choose-right__title m_none" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="4000"
            v-if="fourthblock[0].title" v-html="fourthblock[0].title"></h3>
          <div class="choose-right__card" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="4000"
            data-aos-delay="500">
            <div class="choose-right__card__num">01.</div>
            <div class="choose-right__card__par" v-if="fourthblock[0].description" v-html="fourthblock[0].description">

            </div>
          </div>
          <div class="choose-right__card" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="4000"
            data-aos-delay="1000">
            <div class="choose-right__card__num">02.</div>
            <div class="choose-right__card__par" v-if="fourthblock[1].description" v-html="fourthblock[1].description">
            </div>
          </div>
          <div class="choose-right__card" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="4000"
            data-aos-delay="2000">
            <div class="choose-right__card__num">03.</div>
            <div class="choose-right__card__par" v-if="fourthblock[2].description" v-html="fourthblock[2].description">
            </div>
          </div>
          <div class="choose-right__card" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="4000"
            data-aos-delay="3000">
            <div class="choose-right__card__num">04.</div>
            <div class="choose-right__card__par" v-if="fourthblock[3].description" v-html="fourthblock[3].description">
            </div>
          </div>
        </div>
        <div class="choose-left" v-if="!fourthblock[0].icon && lang == 'kz'">
          <img src="../assets/images/main_bust_kaz.png" alt="" />
        </div>
        <div class="choose-left" v-if="fourthblock[0].icon">
          <img :src="cdn + fourthblock[0].icon" alt="" />
        </div>
      </div>
    </div>
    <!-- <div class="progress">
            <h1>asdasd</h1>
            <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0"
            aria-valuemax="100">
            </div>
        </div> -->
    <div class="trust">
      <div class="container">
        <div class="trust-title" v-if="fifthblock[0].title" v-html="fifthblock[0].title"></div>
        <div class="trust-first">
          <div class="trust-first__card">
            <p class="trust-first__card__num">
              <number v-if="fifthblock[1].title" class="playAnimationNumber" ref="number1" :from="0" animationPaused
                :class="{ scaleBig: scaleClass }" @complete="completed" :to="fifthblock[1].title" :duration="5"
                :delay="0" easing="Power1.easeOut" />
            </p>
            <p class="trust-first__card__par" v-if="fifthblock[1].description" v-html="fifthblock[1].description">

            </p>
          </div>
          <div class="trust-first__card">
            <p class="trust-first__card__num">
              <number v-if="fifthblock[2].title" ref="number2" :from="0" :to="fifthblock[2].title" :duration="5"
                :delay="0" easing="Power1.easeOut" animationPaused :class="{ scaleBig: scaleClass }"
                @complete="completed" />
            </p>
            <p class="trust-first__card__par-desktop" v-if="fifthblock[2].description"
              v-html="fifthblock[2].description">

            </p>
            <!-- <p class="trust-first__card__par-mobile">
              компаний <br />
              выбрали нас
            </p> -->
            <p class="trust-first__card__par-mobile" v-if="fifthblock[2].description"
              v-html="fifthblock[2].description"></p>
          </div>
          <div class="trust-first__card">
            <p class="trust-first__card__num">
              <number v-if="fifthblock[3].title" ref="number3" :from="0" :to="fifthblock[3].title" :duration="5"
                :delay="0" easing="Power1.easeOut" animationPaused :class="{ scaleBig: scaleClass }"
                @complete="completed" />
            </p>
            <p class="trust-first__card__par" v-if="fifthblock[3].description" v-html="fifthblock[3].description">

            </p>
          </div>
        </div>
        <div class="trust-second">
          <div class="trust-first__card">
            <!-- <p class="trust-first__card__num">15</p> -->
            <p class="trust-first__card__num">
              <number v-if="fifthblock[4].title" ref="number4" :from="0" :to="fifthblock[4].title" :duration="5"
                :delay="0" easing="Power1.easeOut" animationPaused :class="{ scaleBig: scaleClass }"
                @complete="completed" />
            </p>
            <p class="trust-first__card__par" v-if="fifthblock[4].description" v-html="fifthblock[4].description">

            </p>
          </div>
          <div class="trust-first__card">
            <p class="trust-first__card__num">
              <number v-if="fifthblock[5].title" ref="number5" :from="0" :to="fifthblock[5].title" :duration="5"
                :delay="0" easing="Power1.easeOut" animationPaused :class="{ scaleBig: scaleClass }"
                @complete="completed" />
            </p>
            <p class="trust-first__card__par-desktop" v-if="fifthblock[5].description"
              v-html="fifthblock[5].description">

            </p>
            <!-- <p class="trust-first__card__par-mobile">
              и более <br />
              частных клиентов
            </p> -->
            <p class="trust-first__card__par-mobile" v-if="fifthblock[5].description"
              v-html="fifthblock[5].description"></p>
          </div>
          <div class="trust-first__card">
            <p class="trust-first__card__num">
              <number v-if="fifthblock[6].title" ref="number6" :from="0" :to="fifthblock[6].title" :duration="5"
                :delay="0" easing="Power1.easeOut" animationPaused :class="{ scaleBig: scaleClass }"
                @complete="completed" />
            </p>
            <p class="trust-first__card__par" v-if="fifthblock[6].description" v-html="fifthblock[6].description">

            </p>
          </div>
        </div>
        <p class="trust-sub" v-if="fifthblock[0].mobile_title" v-html="fifthblock[0].mobile_title"></p>
        <!-- <p class="trust-sub">а также...</p> -->
        <div class="trust-text" v-if="fifthblock[0].mobile_description">
          <p v-html="fifthblock[0].mobile_description"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
  props: ["fourthblock","fifthblock"],
  data() {
    return {
      reached: false,
      scaleClass: false,
      lang: localStorage.getItem('lang') || 'ru'
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    theFormat(number) {
      return number.toFixed(2);
    },
    completed() {
      // console.log("Animation ends!");
      this.scaleClass = true;
    },
    playAnimation() {
      // console.log("Animation starts!");
      this.scaleClass = false;
      this.$refs.number1.play();
      this.$refs.number2.play();
      this.$refs.number3.play();
      this.$refs.number4.play();
      this.$refs.number5.play();
      this.$refs.number6.play();
    },
    restartAnimation() {
      // console.log("Animation starts!");
      this.scaleClass = false;
      this.$refs.number1?.restart();
      this.$refs.number2?.restart();
      this.$refs.number3?.restart();
      this.$refs.number4?.restart();
      this.$refs.number5?.restart();
      this.$refs.number6?.restart();
    },
    task() {
      // console.log("Triggered just one time >= 80");
    },
    handleScroll(event) {
      // Any code to be executed when the window is scrolled
      const offsetTop = window.scrollY || 0;
      let percentage;
      if(window.innerWidth > 768){
        percentage = (offsetTop * 30) / document.body.scrollHeight;
      }
      else{
         percentage = (offsetTop * 100) / document.body.scrollHeight;
      }

      // console.log(percentage)
      if (percentage >= 10) {
        if (!this.reached) {
          this.task();
          this.restartAnimation();
          this.reached = true;
        }
      } else this.reached = false;
    },
  },
  mounted() {
    this.restartAnimation();
  },
  computed: {
    ...mapState(["cdn"])
  }
};
</script>

<style lang="scss" scoped>
@mixin fontStyle($family, $style, $weight, $size, $lheight, $color) {
  font-family: $family;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lheight;
  color: $color;
}

@mixin mobile {
  @media (min-width: 320px) and (max-width: 425px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 425px) and (max-width: 768px) {
    @content;
  }
}

@mixin tablet-1024 {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) and (max-width: 1440px) {
    @content;
  }
}
@mixin desktop-max {
  @media (min-width: 1441px) and (max-width: 2560px) {
    @content;
  }
}
.title-mobile {
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  margin-left: -18%;
  background: linear-gradient(
  90deg,
  #ff143f 0%,
  #c600dd 49.97%,
  #1b27ff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  @include tablet() {
    font-size: 16px;
  }
}
.choose {
  margin-top: 100px;
  margin-bottom: 100px;

  .container {
    display: flex;
    margin: 0 auto;
    max-width: 1320px;
    width: 80%;
  }

  &-left {
    margin-left: 94px;
    img {
      max-width: 475px;
    }
  }

  &-right {
    &__title {
      @include fontStyle(Roboto, normal, 500, 36px, 43px,#373737);
      // text-align: right;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    }

    &__card {
      display: flex;
      align-items: center;
      margin-top: 40px;
      padding: 10px 40px;
      background: #efefef;
      border: 1px solid rgba(255, 255, 255, 0.05);
      box-sizing: border-box;
      backdrop-filter: blur(55px);
      border-radius: 15px;

      &__num {
        @include fontStyle(Roboto, normal, 900, 60px, 82px, transparent);
        background: -webkit-linear-gradient(180deg, #1b27ff 15.3%, #c600dd 42.3%, #ff143f 100.2%);
        -webkit-background-clip: text;
        -webkit-text-stroke: 2px transparent;
        color: #efefef;
        // -webkit-text-stroke-width: 1px;
        // -webkit-text-stroke-color: #acacac;
        align-items: center;
      }

      &__par {
        @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
        margin-left: 20px;
        margin-bottom: 0 !important;
        width: 100%;
        p {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  @include mobile() {
    background: #efefef;
    padding-top: 40px;
    margin-bottom: 0;
    margin-top: 25px !important;
    .container  {
      width: 66%;
    }
    &-right {
      &__title {
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }

      &__card {
        display: none;
      }
    }

    &-left {
      display: none;
    }
  }

  @include tablet() {
    font-size: 16px;
    background: #efefef;
    padding-top: 40px;
    margin-bottom: 0;
    margin-top: 25px !important;
    .container  {
      width: 66%;
    }
    &-right {
      &__title {
        font-size: 18px;
        line-height: 14px;
        text-align: left;
        background: linear-gradient(
        90deg,
        #ff143f 0%,
        #c600dd 49.97%,
        #1b27ff 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      }

      &__card {
        display: none;
      }
    }

    &-left {
      display: none;
    }
  }

  @include tablet-1024() {
    &-left {
      margin-left: 30px;
      width: 40%;
      img {
        max-width: 300px;
      }
    }
    &-right {
      &__title {
        font-size: 26px;
        line-height: 32px;
      }
      &__card {
        padding: 10px 20px;
        &__num {
          font-size: 36px;
          line-height: 42px;
        }
        &__par {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }
  @include desktop() {
    &-left {
      margin-left: 40px;
      img {
        max-width: 350px;
      }
    }
    &-right {
      &__card {
        padding: 10px 20px;
        &__num {
          font-size: 46px;
          line-height: 52px;
        }
        &__par {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }
  }
  // @include desktop-max() {
  //   &-left {
  //     img {
  //       width: 122%;
  //       margin-left: -25px;
  //       margin-top: -36px;
  //       max-width: 652px;
  //     }
  //   }
  // }
  .reveal {
    opacity: 0;
    position: relative;
  }

  .reveal.active {
    opacity: 1;
  }

  .active.fade-top1 {
    animation: fade-top1 1s ease-out;
    // animation-delay: 1s;
  }

  @keyframes fade-top1 {
    0% {
      transform: translateY(-200px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .active.fade-top2 {
    animation: fade-top2 1s ease-out;
    // animation-delay: 3s;
  }

  .fade-top2 {
    animation-delay: 3s;
  }

  @keyframes fade-top2 {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .active.fade-top3 {
    animation: fade-top3 1s ease-out;
    // animation-delay: 4s;
  }

  .fade-top3 {
    animation-delay: 6s;
  }

  @keyframes fade-top3 {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .active.fade-top4 {
    animation: fade-top4 1s ease-out;
    // animation-delay: 5s;
  }

  .fade-top4 {
    animation-delay: 7s;
  }

  @keyframes fade-top4 {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .active.fade-top5 {
    animation: fade-top5 1s ease-out;
    // animation-delay: 6s;
  }

  .fade-top5 {
    animation-delay: 7s;
  }

  @keyframes fade-top5 {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.trust {
  &-title {
    @include fontStyle(Roboto, normal, 500, 36px, 43px, #373737);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    margin-bottom: 50px;
  }

  &-first,
  &-second {
    display: flex;
    justify-content: space-between;

    // align-items: center;
    &__card {
      text-align: center;

      &__num {
        margin-bottom: 0;
        @include fontStyle(Roboto, normal, 700, 60px, 82px, transparent);
        letter-spacing: 0.055em;
        background: -webkit-linear-gradient(
          180deg,
          #1b27ff 15.3%,
          #c600dd 42.3%,
          #ff143f 100.2%
        );
        -webkit-background-clip: text;
        -webkit-text-stroke: 4px transparent;
        color: #ffffff;
      }

      &__par {
        @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
        text-align: center;

        &-desktop {
          @include fontStyle(Roboto, normal, 300, 18px, 22px, #373737);
          text-align: center;
        }

        &-mobile {
          display: none;
        }
      }
    }
  }

  &-second {
    margin-top: 50px;
    margin-bottom: 100px;
  }

  &-sub,
  &-text {
    display: none;
  }

  @include mobile() {
    background: #efefef;
    padding-top: 10px;

    &-title {
      display: none;
    }

    &-sub,
    &-text {
      display: block;
    }

    &-sub {
      @include fontStyle(Roboto, normal, 500, 14px, 14px, transparent);
      background:linear-gradient(90deg, #ff143f 0%, #c600dd 7.97%, #1b27ff 14%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    &-text {
      padding-top: 10px;
      padding-bottom: 20px;
      //changes
      @include fontStyle(Roboto, normal, 400, 14px, 14px, #373737);
    }

    &-first {
      // padding-left: 10px;
      // padding-right: 10px;
      margin-bottom: -20px;
      &__card {
        width: 110px;
        &__par {
          // margin-right: 15px;
          font-size: 14px;
          line-height: 14px;
          text-align: left;
          &-desktop {
            display: none;
          }

          &-mobile {
            margin-bottom: 0;
            display: block;
            width: 100%;
            margin: 0 auto;
            //changes
            @include fontStyle(Roboto, normal, 300, 14px, 14px, #373737);
            text-align: left;
          }
        }

        &__num {
          font-size: 21px;
          line-height: 25px;
          color: #545e60;
          background: transparent;
          text-align: left;
        }
      }
    }

    &-second {
      // padding-left: 20px;
      // padding-right: 10px;
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

  @include tablet() {
    background: #efefef;
    padding-top: 10px;

    &-title {
      display: none;
    }

    &-sub,
    &-text {
      display: block;
    }

    &-sub {
      @include fontStyle(Roboto, normal, 500, 14px, 14px, transparent);
      background:linear-gradient(90deg, #ff143f 0%, #c600dd 7.97%, #1b27ff 14%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }

    &-text {
      padding-top: 10px;
      padding-bottom: 20px;
      //changes
      @include fontStyle(Roboto, normal, 400, 14px, 14px, #373737);
    }

    &-first {
      // padding-left: 10px;
      // padding-right: 10px;
      margin-bottom: -20px;
      &__card {
        width: 26%;
        &__par {
          font-size: 14px;
          line-height: 14px;
          text-align: left;
          &-desktop {
            display: none;
          }

          &-mobile {
            margin-bottom: 0;
            display: block;
            width: 100%;
            margin: 0 auto;
            //changes
            @include fontStyle(Roboto, normal, 300, 14px, 14px, #373737);
            text-align: left;
          }
        }

        &__num {
          font-size: 21px;
          line-height: 25px;
          color: #545e60;
          background: transparent;
          text-align: left;
        }
      }
    }

    &-second {
      margin-top: 35px;
      margin-bottom: 20px;
    }
  }
  @include desktop-max() {
    &-second {
      margin-left: 20px;
    }
  }
}
</style>